<template>
	<div class="calendar">
		<div class="calendar-head">
			<div class="calendar-head-intervals">
				<p>Ganztägig</p>
			</div>

			<div class="calendar-head-day">
				<div :class="`calendar-event calendar-type-${event.type}`" v-for="(event, index) in allDayEvents" :key="index">
					<div class="calendar-label">{{ $t(`status.indicator.${event.type}`) }}</div>
				</div>
			</div>
		</div>

		<div class="calendar-body">
			<div class="calendar-body-scroll-area" ref="scroll-area">
				<div class="calendar-body-pane">
					<div class="calendar-body-day-container">
						<div class="calendar-body-intervals">
							<div class="calendar-interval" v-for="(_, index) in Array(25)" :key="index">
								<div class="calendar-interval-text">{{ index | localeHour }}</div>
							</div>
						</div>

						<div class="calendar-body-day">
							<div class="calendar-day-interval" v-for="(_, index) in Array(25)" :key="index" />
							<div class="calendar-timed-event-container">
								<div :class="`calendar-timed-event calendar-type-${event.type}`" :style="event.style" v-for="(event, index) in timedEvents" :key="index">
									<strong>{{ $t(`status.indicator.${event.type}`) }}</strong>
									<p>{{ eventTime(event) }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Calendar",
	props: {
		events: Array
	},
	methods: {
		eventTime(event) {
			let now = new Date();

			let _startDate = new Date(event.startDate);
			let startTime = _startDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			let _endDate = new Date(event.endDate);
			let endTime = _endDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			if (new Date(_startDate.getTime()).setHours(0,0,0,0) == new Date(now.getTime()).setHours(0,0,0,0) &&
				new Date(now.getTime()).setHours(23,59,59,999) == new Date(_endDate.getTime()).setHours(23,59,59,999))
				return `${startTime} – ${endTime}`;
			return `${_startDate.toLocaleDateString()}, ${startTime} – ${_endDate.toLocaleDateString()}, ${endTime}`;
		},
		minuteDelta(date1, date2) {
			return Math.round(((new Date(date2).getTime() - new Date(date1).getTime()) / 1000) / 60);
		}
	},
	computed: {
		allDayEvents() {
			if (!this.events || !this.events.length) return [];
			return this.events.filter(event => {
				return !event.endDate && new Date(event.startDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
			});
		},
		timedEvents() {
			if (!this.events || !this.events.length) return [];
			return this.events.filter(event => {
				let now = new Date().getTime()
				return event.startDate && event.endDate && (new Date(event.startDate).setHours(0,0,0,0) <= now && now < new Date(event.endDate).setHours(23,59,59,999));
			}).map((event, index) => {
				let top = Math.max((this.minuteDelta(new Date().setHours(0,0,0,0), event.startDate) / 60) * 38, 0);
				let bottom = Math.max((this.minuteDelta(event.endDate, new Date().setHours(23,59,59,999)) / 60) * 38, 0);

				return {
					...event,
					style: {
						top: `${top}px`,
						bottom: `${bottom}px`
					}
				}
			});
		}
	},
	filters: {
		localeHour(value) {
			let date = new Date();
			date.setHours(value, 0, 0, 0);
			return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
		},
		localeTime(date) {
			return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
		}
	}
}
</script>

<style lang="less">
.calendar {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;

	.calendar-head {
		flex: none;
		display: flex;
		background-color: #2C2C2D;
		border-top: 1px solid #54545899;
		border-bottom: 1px solid #54545899;
		padding: 4px 0;

		.calendar-head-intervals {
			flex: none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: relative;
			width: 58px;
			margin-right: 4px;

			p {
				margin: 0;
				font-size: 11px;
			}
		}

		.calendar-head-day {
			flex: 1 1 auto;
			display: flex;
			width: 0;
			position: relative;
			padding: 0 4px;

			.calendar-event {
				flex: 1;
			}
		}
	}

	.calendar-body {
		flex: 1 1 60%;
		overflow: hidden;
		display: flex;
		position: relative;
		flex-direction: column;

		.calendar-body-scroll-area {
			overflow-y: scroll;
			flex: 1 1 auto;
			display: flex;
			align-items: flex-start;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.calendar-body-pane {
				width: 100%;
				height: 936px;
				overflow-y: hidden;
				flex: none;
				display: flex;
				align-items: flex-start;

				.calendar-body-day-container {
					display: flex;
					flex: 1;
					width: 100%;
					height: 100%;
					padding: 12px 0;

					.calendar-body-intervals {
						flex: none;
						user-select: none;
						width: 58px;
						height: 38px;
						margin-right: 8px;

						.calendar-interval {
							height: 38px;
							text-align: right;
							position: relative;

							.calendar-interval-text {
								display: block;
								position: relative;
								top: -7px;
								font-size: 11px;
								color: var(--light-text-color);
								padding-right: 4px;
							}
						}
					}

					.calendar-body-day {
						flex: 1;
						width: 0;
						position: relative;

						.calendar-day-interval {
							height: 38px;
							box-shadow: inset 0 1px 0 0 var(--separator-color);
						}

						.calendar-timed-event-container {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	.calendar-event {
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 12px;
		padding-left: 6px;
		z-index: 1;

		& + .calendar-event {
			margin-left: 1px;
		}

		&.calendar-type-offline {
			background-color: #FF453A33;
			color: var(--system-red-color);
		}

		&.calendar-type-maintenance {
			background-color: #FFD60A33;
			color: var(--system-yellow-color);
		}

		&.calendar-type-online {
			background-color: #30D15833;
			color: var(--system-green-color);
		}
	}

	.calendar-timed-event {
		position: absolute;
		width: 100%;
		min-height: 38px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 11px;
		border-left: 2px solid;
		padding: 4px 6px;

		&.calendar-type-offline {
			background-color: #FF453A33;
			border-color: var(--system-red-color);
			color: var(--system-red-color);
		}

		&.calendar-type-maintenance {
			background-color: #FFD60A33;
			border-color: var(--system-yellow-color);
			color: var(--system-yellow-color);
		}

		&.calendar-type-online {
			background-color: #30D15833;
			border-color: var(--system-green-color);
			color: var(--system-green-color);
		}
	}
}
</style>