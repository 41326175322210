import { get } from "axios";

import Calendar from "@/components/Calendar.vue"

export default {
	name: 'Dashboard',
	components: {
		Calendar
	},
	data() {
		return {
			services: null,
			lastUpdated: 0,
			dataUpdateIntervalId: null
		}
	},
	mounted() {
		this.dataUpdateIntervalId = setInterval(this.updateData, 60 * 1000);
		document.addEventListener("visibilitychange", this.visibilityChanged, false);

		this.updateData();
	},
	methods: {
		eventTime(event) {
			let now = new Date();

			let _startDate = new Date(event.startDate);
			let startTime = _startDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			if (_startDate.getTime() > now.getTime()) return `${this.$t('status.events.from_date', { date: _startDate.toLocaleDateString(), time: startTime })}`;
			if (!event.endDate && !event.resolved) return `${this.$t('status.events.since_date', { date: _startDate.toLocaleDateString(), time: startTime })}`;
			if (!event.endDate && event.resolved) return `${_startDate.toLocaleDateString()}, ${startTime}`;

			let _endDate = new Date(event.endDate);
			let endTime = _endDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

			if (new Date(_startDate.getTime()).setHours(0,0,0,0) == new Date(now.getTime()).setHours(0,0,0,0) &&
				new Date(now.getTime()).setHours(23,59,59,999) == new Date(_endDate.getTime()).setHours(23,59,59,999))
				return `${startTime} – ${endTime}`;
			return `${_startDate.toLocaleDateString()}, ${startTime} – ${_endDate.toLocaleDateString()}, ${endTime}`;
		},
		todayEvents(service) {
			return service.events.filter(event => {
				if (!event.endDate) return false;

				let now = new Date().getTime();
				return new Date(event.startDate).setHours(0,0,0,0) <= now && now < new Date(event.endDate).setHours(23,59,59,999);
			});
		},
		async updateData() {
			// return;
			let response = await get(`//${location.hostname}/system-status.json`);
			// let response = await get(`https://status.festival.tf/system-status.json`);

			if (response.status == 200) {
				this.services = response.data;
			}

			this.lastUpdated = new Date().getTime();
		},
		visibilityChanged() {
			if (document.hidden) {
				clearInterval(this.dataUpdateIntervalId);
			} else {
				if (new Date().getTime() - this.lastUpdated >= (60 * 1000)) {
					this.updateData();
					this.dataUpdateIntervalId = setInterval(this.updateData, 60 * 1000);
				} else {
					setTimeout(() => {
						this.updateData();
						this.dataUpdateIntervalId = setInterval(this.updateData, 60 * 1000);
					}, (60 * 1000) - (new Date().getTime() - this.lastUpdated));
				}
			}
		}
	},
	computed: {
		paginatedServices() {
			return this.services.reduce((out, item, index) => {
				let chunkIndex = Math.floor(index / 3);
				if (!out[chunkIndex]) out[chunkIndex] = [];

				out[chunkIndex].push(item);
				return out;
			}, []);
		}
	},
	filters: {
		date(value) {
			return new Date(value).toLocaleString();
		}
	}
}